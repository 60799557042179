<template>
  <div class="page_404">
    <div class="about">
      <!--      <a-->
      <!--        class="bg_links social portfolio"-->
      <!--        href="https://www.rafaelalucas.com"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <span class="icon"></span>-->
      <!--      </a>-->
      <!--      <a-->
      <!--        class="bg_links social dribbble"-->
      <!--        href="https://dribbble.com/rafaelalucas"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <span class="icon"></span>-->
      <!--      </a>-->
      <!--      <a-->
      <!--        class="bg_links social linkedin"-->
      <!--        href="https://www.linkedin.com/in/rafaelalucas/"-->
      <!--        target="_blank"-->
      <!--      >-->
      <!--        <span class="icon"></span>-->
      <!--      </a>-->
      <a class="bg_links logo"></a>
    </div>
    <!-- end about -->

    <nav>
      <div class="menu">
        <router-link class="website_name" to="/"></router-link>

        <div class="menu_links">
          <router-link
            v-for="link in links"
            :key="link.url"
            :to="link.url"
            active-class="active_link"
            class="link"
            >{{ link.title }}</router-link
          >
        </div>
        <div class="menu_icon">
          <span class="icon"></span>
        </div>
      </div>
    </nav>

    <section class="wrapper">
      <div class="container">
        <div id="scene" class="scene" data-hover-only="false">
          <div class="circle" data-depth="1.2"></div>

          <div class="one" data-depth="0.9">
            <div class="content">
              <span class="piece"></span>
              <span class="piece"></span>
              <span class="piece"></span>
            </div>
          </div>

          <div class="two" data-depth="0.60">
            <div class="content">
              <span class="piece"></span>
              <span class="piece"></span>
              <span class="piece"></span>
            </div>
          </div>

          <div class="three" data-depth="0.40">
            <div class="content">
              <span class="piece"></span>
              <span class="piece"></span>
              <span class="piece"></span>
            </div>
          </div>

          <p class="p404" data-depth="0.50">404</p>
          <p class="p404" data-depth="0.10">404</p>
        </div>

        <div class="text">
          <article>
            <p>Ой, наверное вы заблудились,<br />такой страницы нет.</p>
            <router-link to="/" tag="button">На главную</router-link>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Page-404",
  data() {
    return {
      links: [
        { title: "Новый заказ", url: "/new_order" },
        { title: "Настройки", url: "/settings/my_settings" },
        // { title: "База знаний", url: "/knowledge_base" },
      ],
    };
  },
  mounted() {
    // var scene = document.getElementById("scene");
    // // eslint-disable-next-line no-unused-vars,no-undef
    // new Parallax(scene);
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/page_404.scss";
</style>
